import React from 'react';
import { Provider } from 'react-redux';
import store from './logic/store/AppStore';
import { OverrideService } from './logic/services';
import AppToastAlert from './components/elements/AppToastAlert';
import AppContainer from './AppContainer';

import './styles/style.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'font-awesome/css/font-awesome.min.css';

export interface LaunchPadAppProps {
  configuration: any;
}

export default class LaunchPadApp extends React.Component<any, any> {
  constructor(props) {
    super(props);
    OverrideService.storeConfiguration(props.configuration);
  }

  render() {
    // if (!OverrideService.configuration) return null;
    return (
      <Provider store={store}>
        <AppContainer />
        <AppToastAlert />
      </Provider>
    );
  }
}
