import numeral from 'numeral';
import CurrencyHelper from './CurrencyHelper';
import OverrideService from '../logic/services/overrides/OverrideService';

export function currency(value = 0, symbol = false, skipDecimal = false) {
  const negative = value < 0;
  value = Math.abs(value);
  const val = skipDecimal
    ? numeral(Math.abs(value)).format('0,0')
    : numeral(Math.abs(value)).format('0,0.00');

  let returnValue = val;

  // if (!value % 1 === 0) {
  //   returnValue = parseFloat(Math.round(value * 100) / 100).toFixed(2);
  // } else {
  // }
  // if (value % 1 === 0) {
  //   returnValue = parseInt(value, 10);
  // }

  if (symbol) {
    let currencySymbol = null;
    if (symbol === true) {
      currencySymbol = CurrencyHelper.getCurrencySymbol();
    } else {
      currencySymbol = CurrencyHelper.getCurrencySymbol(symbol);
    }

    const beforeOrAfter = CurrencyHelper.beforeOrAfter(currencySymbol);

    returnValue =
      OverrideService.getConfig().currencyFirst === true || beforeOrAfter === -1
        ? currencySymbol + returnValue
        : returnValue + currencySymbol;
  }

  if (negative) {
    returnValue = `-${returnValue}`;
  }

  return returnValue;
}

export default {
  currency
};
