import React from 'react';
import { Link } from 'react-router-dom';
import { AppIcon } from '@launchpad';

export default class KabnFooter extends React.PureComponent<any, any> {
  render() {
    return (
      <div className="row">
        <div className="col-lg-7 footer-column">
          <h3>About Liquid Avatar:</h3>
          <p className="footer-link">
            Liquid Avatar Technologies Inc., through its wholly owned subsidiary
            Liquid Avatar Operations Inc. focuses on the verification,
            management and monetization of Self Sovereign Identity, empowering
            users to control and benefit from the use of their online identity.
          </p>
          <p className="footer-link">
            The Liquid Avatar Mobile App, available in the Apple App Store and
            Google Play is a verified Self Sovereign Identity platform that
            empowers users to create high quality digital icons representing
            their online personas. These icons allow users to manage and control
            their digital identity and Verifiable Access and Identity
            Credentials, and to use Liquid Avatars to share public and
            permission based private data when they want and with whom they
            want&nbsp;
            <a
              target="_blank"
              // className="footer-link"
              style={{ color: '#7a7afc' }}
              href="https://liquidavatar.com/"
            >
              www.liquidavatar.com
            </a>
            .
          </p>
          <p className="footer-link">
            Liquid Avatar has a suite of revenue generating programs including
            KABN KASH a cash back and reward program that has over 400 leading
            online merchants and coming soon, an integrated offering engine. In
            Canada, Liquid Avatar also has the KABN Visa Card, including a
            mobile card application p that allows users to manage and control a
            range of financial services for traditional and digital currencies.
          </p>
        </div>
        <div className="col-lg-2 footer-column mt-4 mt-lg-0">
          <h3>INFORMATION</h3>
          <Link to="/terms-conditions" className="footer-link">
            T&Cs
          </Link>
          <Link to="/privacy-policy" className="footer-link">
            Privacy Policy
          </Link>
          <Link to="/faq" className="footer-link">
            FAQ
          </Link>
        </div>
        <div className="col-lg-3 footer-column last-column mt-4 mt-lg-0">
          <h3>CONNECT WITH US</h3>
          <div>
            <a target="_blank" href="https://www.facebook.com/liquidavatar">
              <AppIcon
                name="fa.facebook-square"
                style={{ fontSize: 40, color: 'white' }}
              />
            </a>
            <a target="_blank" href="https://twitter.com/liquid_avatar">
              <AppIcon
                name="fa.twitter"
                style={{ fontSize: 40, color: 'white' }}
              />
            </a>
            <a target="_blank" href="https://www.instagram.com/liquid.avatar/">
              <AppIcon
                name="fa.instagram"
                style={{ fontSize: 40, color: 'white' }}
              />
            </a>
          </div>
          <div className="mt-5">
            <h3>Contact Us</h3>
            <div>
              <a className="footer-link" href="tel:18882577561">
                1-888-257-7561
              </a>
            </div>
          </div>
        </div>

        <div className="last-line" style={{ bottom: 10 }}>
          <p>© KABN card {new Date().getFullYear()} copyright</p>
        </div>
      </div>
    );
  }
}
