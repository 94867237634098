import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class ChartModel extends BaseModel {

  async getChartData(period = 'week') {
    let result = await Api.call(
      'spending/chart',
      {
        period: period
      }
    );

    if (result.success) {
      return result.data;
    }

    return null;
  }
}
