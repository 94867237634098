import {
  MEMBER_AUTH_LOADED,
} from '../auth/AuthActions'

export function wallets(state = [], action: { type: any; payload: { wallets: any; }; }) {
  switch(action.type) {
    case MEMBER_AUTH_LOADED:
     return action.payload.wallets || [];
  }
  return [...state];
}


export default wallets;
