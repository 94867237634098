import { MEMBER_AUTH_UPDATE_STORE } from '../auth/AuthActions'

export function accounts(state = [], action: { type: any; payload: { accounts: any; }; }) {
  switch (action.type) {
    case MEMBER_AUTH_UPDATE_STORE:
      return action.payload.accounts || [];
  }
  return [...state];
}


export default accounts;
