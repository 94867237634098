import React from 'react';
import { AppIcon } from '../../index';

export default class AppCard extends React.PureComponent<any, any> {
  render() {
    return (
      <div className="app-widget">
        <div className="card-header">
          <AppIcon
            name={`fa.${this.props.name}`}
            style={{ fontSize: 25, color: 'white' }}
          />
          <p>{this.props.title}</p>
        </div>
        <div className="card-content">{this.props.children}</div>
      </div>
    );
  }
}
