import React from 'react';
import AppStore from '../../logic/store/AppStore';
import Config from '../../config';
import AppImage from './AppImage';

export default class ProfileImage extends React.Component<any, any> {

  getProfileImage(profile: any) {
    if(!profile) {
      profile = AppStore.getState().auth.profile;
    }

    if(!profile || !profile.imagePath) {
      return require('../../assets/profile-image-dummy.png');
    }

    return Config.publicUrl + profile.imagePath

  }

  render() {

    let profile = this.props.profile;

    let profileImage = this.getProfileImage(profile);

    return (
      <AppImage src={profileImage} alt="profile image" style={{width: '100%', height: 'auto'}} />
    )
  }
}
