import React from 'react';
import { connect } from 'react-redux';
import ProfileImage from '../../components/images/ProfileImage';
import DateHelper from '../../util/DateHelper';
import { Link } from 'react-router-dom';
import { AppLayoutWithCard, FormSubmitWrapper, FormHandler, InputField, AppCheckboxInput, AppButton } from '../../index';
import SendMoneyModal from './models/SendMoneyModel';
import FormComponent from '../../components/crud/FormComponent';


class SendMoney extends React.Component<any, any> {
  formHandler: FormHandler;

  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new SendMoneyModal({
        name: '',
        email: '',
        refNumber: ''
      }), {
      redirect: false,
      rememberDetails: true,
      amount: '£0.00'
    }
    );
    this.formHandler.on('submitSuccess', (data: { success: any; }) => {
      if (data.success) {
        setTimeout(() => {
          this.setState({
            redirect: true
          })
        }, 3000)
      }
    })
  }
  _getModalMessage(data: { success: any; message: any; }) {
    if (!data.success) {
      return data.message
    }
  }

  validateInput(e) {
    const re = /[0-9\.]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    let textValue = e.target.value;
    if(textValue[0] == '£') {
      textValue = textValue.substr(1);
    }

    if(textValue.split('.').length > 2) {
      this.setState({
        amount: this.state.amount
      })
      return;
    }

    if(textValue.length) {
      var regexp = /^\d+(\.\d{0,2})?$/;

    // returns true
      if(!regexp.test(textValue)) {
        this.setState({
          amount: this.state.amount
        });
        return;
      }
    }


    let value = parseFloat(textValue);

    if(!value) value = 0;

    if(value < 0 || value > 250) {
      this.setState({
        amount: this.state.amount
      })
    } else {
      this.setState({
        amount: '£' + textValue
      })
    }
  }

  onFocus() {
    if(this.state.amount == '£0.00') {
      this.setState({ amount: '£'})
    }
  }

  onBlur() {
    if(this.state.amount == '£') {
      this.setState({ amount: '£0.00'})
    }
  }

  render() {

    let profile = this.props.profile;
    let formHandler = this.formHandler;


    return (
      <div className='appContainer'>
        <div className='container'>
          <div className='row'>
            <FormComponent formHandler={formHandler} id='send-money-form'>

              <div className='col-md-6 profile-container' style={{margin: 10}}>
                <h1>Send Money</h1>
                <h4>Enter payment details</h4>
                <div className="login-input-wrapper">

                  <InputField
                    className={'input-group'}
                    materialProps={{
                      fullWidth: true
                    }}
                    autoComplete={this.state.record.name}
                    label=""
                    name="name"
                    placeholder={'Full Name of Recipient'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.name}
                    handler={formHandler} />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className={'input-group'}
                    materialProps={{
                      fullWidth: true
                    }}
                    autoComplete={this.state.record.email}
                    label=""
                    name="email"
                    placeholder={'E-mail'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.email}
                    handler={formHandler} />
                </div>
                <div className="login-input-wrapper">
                  <InputField
                    className={'input-group'}
                    materialProps={{
                      fullWidth: true
                    }}
                    autoComplete={this.state.record.refNumber}
                    label=""
                    type="number"
                    name="refNumber"
                    placeholder={'Recipient Reference Number'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.record.refNumber}
                    handler={formHandler} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                  <AppCheckboxInput
                    name='rememberDetails'
                    id='rememberDetails'
                    htmlFor='rememberDetails'
                    labelText='Remember recipient details'
                    checked={this.state.rememberDetails}
                    handler={this.formHandler}
                  />
                  <Link to='/' >View saved recipients</Link>
                </div>
              </div>

              <div className='col-md-6 profile-container' style={{margin: 10}}>
                <h4 style={{paddingTop: 50}}>Enter payment details</h4>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, flexDirection:'column', paddingTop: 20}}>
                <input value={this.state.amount}  className="amount-input" onKeyPress={(e) => this.validateInput(e)} onChange={val => this.onChange(val)} onFocus={(e) => this.onFocus()} onBlur={()=> this.onBlur()}/>
                <p style={{ marginTop: 10, marginBottom: 100, color: '#ADADAD' }}>Add note to recipient</p>

                <AppButton onClick={() => this.formHandler.handleSubmit()}>SEND PAYMENT</AppButton>
                </div>
              </div>
            </FormComponent>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: { auth: { profile: any; }; }) => {
  return {
    profile: state.auth.profile
  }
}

export default connect(mapStateToProps)(SendMoney);
