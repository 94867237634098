import React, { Component } from 'react';
import { AppButton, BottomWaveForLoadingPage } from '../../../index';
import { Link } from 'react-router-dom';
import { LPOverridable } from '../../../index';

class WelcomePage extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
    }
  }

  goToLoginPage() {
    this.props.history.push('/login')
  }

  render() {
    return (
      <LPOverridable name={'welcome.page.content'} history={this.props.history}>
        <div className='login-overlay-container'>
          <img className='logo' src={require('../../../assets/Launchpad_logo.png')} alt="logo" />
          <img src={require('../../../assets/overlay_background.png')} className='overlay-background' alt="" />
          <div className='center-element'>
            <h1>Welcome to Launchpad</h1>
            <h3>Launch Your Digital Banking Strategy with us</h3>
            <AppButton onClick={() => this.goToLoginPage()}>SIGN IN</AppButton>
          </div>

          <BottomWaveForLoadingPage />

          <div className='sign-up-holder'>
            <p>New user?</p>
            <Link to='' className="sign-up-link">Sign Up</Link>
          </div>
        </div>
      </LPOverridable>
    )
  }
}

export default (WelcomePage);
