import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class LegalModel extends BaseModel {
  /**
   * Get table name
   */
  getTableName() {
    return 'app/legal/terms';
  }

  getPrivacyPolicy() {
    return 'app/legal/privacy';
  }

  //  async updateLegals(legals) {

  //   if(!legals.privacy || !legals.terms) {
  //     return;
  //   }

  //    let dbLegals = await this.find('all', { fields: ['version', 'type']});

  //    let dbTerms = dbLegals.find(x => x.type == 'terms');
  //    let dbPrivacy = dbLegals.find(x => x.type == 'privacy');

  //    if(!dbTerms || !dbPrivacy) {
  //      return await this.refreshLegals();
  //    }

  //    if((dbTerms && legals.terms.version != dbTerms.version) ||
  //       (dbPrivacy && legals.privacy.version != dbPrivacy.version)) {
  //         return await this.refreshLegals();
  //       }
  //  }

  //  async refreshLegals() {
  //    let result = await Api.call('app/legal');
  //    return await this.saveLegals(result.data);
  //  }

  //  async saveLegals(legals) {
  //    await this.deleteAll();
  //    await this.save({...legals.terms, type: 'terms', id: null});
  //    await this.save({...legals.privacy, type: 'privacy', id: null});
  //  }

  async getTerms() {
    const terms = await Api.call(this.getTableName());
    return terms;
  }

  async getPrivacy() {
    const privacy = await Api.call(this.getPrivacyPolicy());
    return privacy;
  }
}
