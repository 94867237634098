import AppCard from '@launchpad/components/elements/AppCard';
import LatestTransactions from '@launchpad/modules/dashboard/components/LatestTransactions';
import React from 'react';
import { date } from '@launchpad/util/DateHelper';
import CardImage from './components/CardImage';
import BottomShapeForLoginPage from './elements/BottomShapeForLoginPage';
import KabnBottomWaveForMainPage from './elements/KabnBottomWaveForMainPage';
import KabnWelcomePage from './modules/auth/KabnWelcomePage';
import KabnActivateCardModal from './modules/card/KabnActivateCardModal';
import KabnFooter from './components/KabnFooter';

const overrides = {
  welcome: {
    page: {
      content: (props: any) => <KabnWelcomePage {...props} />
    }
  },
  transaction: { details: { modal: { bottom: () => null } } },
  login: {
    page: {
      logo: () => (
        <img
          className="logo"
          src={require('../../assets/img/KABN-LOGO.png')}
          alt="kabn-logo"
        />
      ),
      bottom: () => null,
      bottomText: () => null
    }
  },
  sidemenu: {
    logo: () => (
      <div className="dash-logo">
        <img src={require('../../assets/img/KABN-LOGO.png')} alt="logo" />
      </div>
    )
  },
  dashboard: {
    bottom: () => <KabnBottomWaveForMainPage />,
    custom: {
      content: () => (
        <div className="d-flex align-items-center justify-content-center mt-5 mb-3">
          <img
            src={require('../../assets/img/Liquid-Avatar-Logo.png')}
            alt="Liquid-Avatar-Logo"
            style={{ height: 'auto', width: '100%', maxWidth: 300 }}
          />
        </div>
      )
    }
  },
  notificationsPage: { page: { header: () => null } },
  card: {
    page: {
      'fx-calculator': () => null,
      disableInternet: () => null,
      autoTopUp: () => null,
      'card-activation-modal': () => <KabnActivateCardModal />,
      lockModalNumber: () => (
        <a href="tel:18882577561" className="report-number">
          <strong>1-888-257-7561</strong>
        </a>
      )
    },
    image: () => <CardImage />,
    'send-money-btn': () => null,
    expires: () => null
  },
  showPin: {
    page: {
      'change-pin-atm': () => null
      // 'no-pin': () => (
      //   <AppCard name="exchange" title="Latest Transactions">
      //     <LatestTransactions />
      //   </AppCard>
      // )
    }
  },
  'custom-load-modal-style': { height: 'auto', minHeight: 0 },
  'load-account-content': props => (
    <div>
      <p>To load funds to your KABN Card, please send an e-Transfer to:</p>
      <a href="mailto: kabncardload@liquidavatar.com">
        kabncardload@liquidavatar.com
      </a>
      <p className="my-3">
        Please include the following details in the{' '}
        <strong>e-Transfer Message Field</strong> to ensure the prompt loading
        of your card:
      </p>
      <ul className="mb-3">
        <li>• First & Last Name</li>
        <li>• Customer ID as displayed below</li>
      </ul>
      <div className="my-4 text-center">
        <p>Your Customer ID:</p>
        <p>{props.profile.customerReference || '-'}</p>
      </div>
      <p style={{ color: 'red' }}>
        The funds will be loaded to your card within 2 business days.
      </p>
    </div>
  ),
  'send-request-btn': () => null,
  profile: {
    basicInfo: (props: any) => (
      <ul>
        <li>
          First name: <span>{props.profile.firstName || '-'}</span>
        </li>
        <li>
          Last name: <span>{props.profile.lastName || '-'}</span>
        </li>
        <li>
          Date of birth:{' '}
          <span>
            {props.profile.birthDate ? date(props.profile.birthDate) : '-'}
          </span>
        </li>
      </ul>
    ),
    addressInfo: (props: any) => (
      <ul>
        <li>
          Address: <span>{props.profile.addressOne || '-'}</span>
        </li>
        <li>
          Apartment/Suite: <span>{props.profile.addressTwo || '-'}</span>
        </li>
        <li>
          City: <span>{props.profile.city || '-'}</span>
        </li>
        <li>
          Province: <span>{props.profile.state || '-'}</span>
        </li>
        <li>
          Country: <span>{props.profile.country.name || '-'}</span>
        </li>
        <li>
          Postal Code: <span>{props.profile.postCode || '-'}</span>
        </li>
      </ul>
    ),
    contactInfo: (props: any) => (
      <ul>
        <li>
          Email: <span>{props.profile.email || '-'}</span>
        </li>
        <li>
          Phone Number: <span>{props.profile.mobile || '-'}</span>
        </li>
      </ul>
    ),
    customerReferenceText: () =>
      'Provide this reference number to your fellow KABN Cardholders who want to send you money from their card.'
  },
  footer: {
    content: () => <KabnFooter />
  },
  'inverted-button': true
};

export default overrides;
