import React, { Component } from 'react';
import KabnButton from '../../components/KabnButton';

class KabnWelcomePage extends Component<any, any> {
  goToLoginPage() {
    this.props.history.push('/login');
  }

  render() {
    return (
      <div className="login-overlay-container">
        <img
          className="logo"
          src={require('../../../../assets/img/KABN-LOGO.png')}
          alt="logo"
        />
        <div className="center-element">
          <h1>Welcome to KABN</h1>
          <h3>
            A suite of Financial Services that starts with Blockchain Identity
          </h3>
          <KabnButton onClick={() => this.goToLoginPage()} inverted>
            SIGN IN
          </KabnButton>
        </div>
      </div>
    );
  }
}

export default KabnWelcomePage;
