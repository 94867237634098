import React from 'react';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import NumberHelper from '../../../util/NumberHelper';
import DateHelper from '../../../util/DateHelper';
import { AppIcon, AppButton, LPOverridable } from '../../../index';

export default class TransactionInfoModal extends React.PureComponent {
  render() {
    const { transaction } = this.props;
    const isFX =
      transaction.originalAmountCurrency &&
      transaction.originalAmountCurrency !== transaction.amountCurrency;
    const amount =
      transaction.amount === 0
        ? NumberHelper.currency(transaction.fee, transaction.amountCurrency)
        : NumberHelper.currency(transaction.amount, transaction.amountCurrency);

    const formatedType = transaction.typeReadable
      ? transaction.typeReadable.replace('_', ' ')
      : transaction.typeReadable;
    const dataToShow = [
      {
        label: 'Type',
        className: 'transaction-type',
        value: formatedType
      },
      {
        label: 'Status',
        className: 'transaction-status',
        value: transaction.statusReadable
      },
      {
        label: 'Transaction Date',
        className: 'transaction-date',
        value: DateHelper.fullDate(transaction.postDate)
      },
      {
        label: 'Settlement Date',
        className: 'transaction-settlement-date',
        value: DateHelper.fullDate(transaction.transactionDate)
      }
    ];

    let fee = parseFloat(transaction.fee);
    if (parseFloat(transaction.fee)) {
      if (isFX && transaction.status === 'settled') {
        fee -= parseFloat(transaction.fxPadding);
      }
    }

    if (transaction.amount === 0 && transaction.fee > 0) {
      fee = null;
    }

    if (isFX) {
      const rate = Math.abs(transaction.originalAmount / transaction.amount);
      dataToShow.push({
        label: 'Spent',
        className: 'transaction-spent',
        value: NumberHelper.currency(
          transaction.originalAmount,
          transaction.originalAmountCurrency
        )
      });
      dataToShow.push({
        label: 'FX rate',
        className: 'transaction-fx-rate',
        value: `${NumberHelper.currency(
          1,
          transaction.amountCurrency
        )} = ${NumberHelper.currency(rate, transaction.originalAmountCurrency)}`
      });
      if (fee) {
        dataToShow.push({
          label: 'Fee',
          className: 'transaction-fee',
          value: NumberHelper.currency(
            parseFloat(transaction.fee) - parseFloat(transaction.fxPadding),
            transaction.amountCurrency
          )
        });
      }
    } else if (fee) {
      dataToShow.push({
        label: 'Fee',
        className: 'transaction-fee',
        value: NumberHelper.currency(
          parseFloat(transaction.fee),
          transaction.amountCurrency
        )
      });
    }

    dataToShow.push({
      label: 'Running balance',
      className: 'transaction-running-balance',
      value: NumberHelper.currency(
        transaction.runningBalance,
        transaction.amountCurrency
      )
    });

    const fullDate = DateHelper.fullDate(transaction.transactionDate);

    return (
      <ModalDialog id="TRANSACTION_INFO" show>
        <div
          className={`transaction-modal ${
            transaction.status ? `transaction-status-${transaction.status}` : ''
          }`}
        >
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                color: 'rgba(0,0,0,0.4)',
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              minWidth: '80%'
            }}
          >
            <h4>{transaction.note}</h4>
            <span className="icon-holder">
              <i
                className="fa fa-info"
                aria-hidden
                style={{ color: 'white', fontSize: 31 }}
              />
            </span>
            <div>{fullDate}</div>
            <p className="tx-amount">{amount}</p>
            <div className="transaction-details">
              {dataToShow.map((x, index) => {
                return (
                  <div
                    className={`row ${x.className ? x.className : ''}`}
                    key={`transaction${index}`}
                  >
                    <div className="col-xs-6 detail-label">{x.label}:</div>
                    <div className="col-xs-6 detail-value">{x.value}</div>
                  </div>
                );
              })}
            </div>
            {transaction.fxFee && (
              <a
                // eslint-disable-next-line no-script-url
                href="javascript:void(0)"
                style={{
                  marginBottom: 20,
                  textDecoration: 'underline',
                  color: '#ad8a4e'
                }}
                onClick={() =>
                  ModalService.showModal('FX_INFO', { transaction })
                }
              >
                Transaction Fees
              </a>
            )}
          </div>
          <LPOverridable name="transaction.details.modal.bottom">
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AppButton
                customstyle={{ maxWidth: 450, maxHeight: 60 }}
                onClick={() => ModalService.hideModal()}
              >
                YOU DON’T RECOGNIZE THIS TRANSACTION?
              </AppButton>
            </div>
          </LPOverridable>
        </div>
      </ModalDialog>
    );
  }
}
