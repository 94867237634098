import React from 'react';
import ModalService from '../../../logic/services/ModalService';
import { ModalDialog } from '../../../index';

export const NAME = 'INFO_MODAL';

export default class InfoModal extends React.Component{

  _onClose() {
    ModalService.hideModal(NAME);
  }

  render() {

    let modalIcon = null;
    return (
      <div>
        <ModalDialog
          show={true}
          onClose={() => this._onClose()}
          style={{
            zIndex: 999999999999
          }}
          {...this.props}
        >
          <div className="lp-modal">
            {modalIcon}
            <h3 style={{ marginTop: 20, textAlign: 'center', fontSize: '1.2rem' }}>{this.props.text}</h3>
            {this.props.closeable ? (<a href="javascript:void(0)" onClick={() => this._onClose()} className="orange-button small-button" style={{ marginTop: 20 }}>Close</a>) : null}
          </div>
        </ModalDialog>
      </div>
    );
  }
}
