import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import LegalModel from './model/LegalModel';

export default class TermsAndConditions extends Component {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      terms: null,
      loading: true
    };
  }

  componentDidMount() {
    new LegalModel().getTerms().then(terms => {
      this.setState({
        terms,
        loading: false
      });
      if (terms.text === '') {
        this.setState({
          loading: true
        });
      }
    });
  }

  render() {
    const content =
      this.state.terms && this.state.terms.data
        ? this.state.terms.data.text
        : null;
    let contentElement = null;
    if (content) {
      contentElement = this.state.terms.data.text;
    }
    // const modified = this.state.terms ? this.state.terms.modified : null;
    return (
      <div className="appContainer">
        <h1 className="legal-title">Terms & Conditions</h1>
        <div className="container">
          <div className="card-wrapper pt-3">
            {this.state.loading ? (
              <CircularProgress className="circle-progress" size={40} />
            ) : (
              <div className="col-12 white-box no-shadow">
                <div
                  className="legal-description cms-content"
                  style={{ background: 'transparent', paddingTop: 0 }}
                  dangerouslySetInnerHTML={{ __html: contentElement }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
