/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationModel from './models/NotificationModel';
import { AppList } from '../../index';
import AuthService from '../../logic/services/AuthService';
import NotificationListItem from './components/NotificationListItem';
import LPOverridable from '../../components/overrides/LPOverridable';

class NotificationsPage extends Component<any, any> {
  list: any;

  constructor(props: any) {
    super(props);
    setTimeout(() => {
      AuthService.refreshAuth();
    }, 4000);
  }

  componentDidMount() {
    new NotificationModel().seenNotifications();
  }

  _renderNotificationItem(i: any) {
    return (
      <NotificationListItem
        notification={i}
        key={i.id}
        closeNotification={() => this.refreshNotifications()}
      />
    );
  }

  refreshNotifications() {
    if (this.list) {
      this.list._reset().then(() => {
        this.list._refresh();
      });
    }
  }

  render() {
    // let unreadNotifications = null;

    // if (this.props.profile && this.props.profile.unreadNotifications) {
    //   unreadNotifications = (
    //     <div className="notificationNumber">
    //       <span>{this.props.profile.unreadNotifications}</span>
    //     </div>
    //   );
    // }

    return (
      <div className="container">
        <div style={{ paddingTop: 100 }}>
          <div
            className="profile-container"
            style={{ height: '70vh', overflow: 'auto' }}
          >
            <div className="row ">
              <div className="col-lg-12 notifications-col-12">
                <LPOverridable name="notificationsPage.page.header">
                  <div className="notifications-header">
                    <button type="button" className="select-all-button">
                      <span>Select All</span>
                    </button>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="dropdown notifications-dropdown">
                        <button
                          type="button"
                          className="dropdown-toggle notifications-dropdown-button"
                          data-toggle="dropdown"
                        >
                          <span>More</span>
                          {/* <i className="fa fa-caret-down"></i> */}
                        </button>
                        <ul className="dropdown-menu" id="moreDropdown">
                          <li>Archive</li>
                          <li>Mark as read</li>
                          <li>Mark as unread</li>
                        </ul>
                      </div>
                      <div className="dropdown notifications-dropdown">
                        <button
                          type="button"
                          className="dropdown-toggle notifications-dropdown-button"
                          data-toggle="dropdown"
                        >
                          <span>Type</span>
                          {/* <i className="fa fa-caret-down"></i> */}
                        </button>
                        <ul className="dropdown-menu" id="moreDropdown">
                          {/* <span className="arrow-up"></span>
                    <span className="cover-arrow"></span> */}
                          <li>
                            System message{' '}
                            <img
                              src={require('../../assets/Tick.svg')}
                              className="check-icon"
                              alt=""
                            />
                          </li>
                          <li>
                            Promotion and offers{' '}
                            <img
                              src={require('../../assets/Tick.svg')}
                              className="check-icon"
                              alt=""
                            />
                          </li>
                          <li>
                            Incoming transactions{' '}
                            <img
                              src={require('../../assets/Tick.svg')}
                              className="check-icon"
                              alt=""
                            />
                          </li>
                          <li>
                            Outgoing transactions{' '}
                            <img
                              src={require('../../assets/Tick.svg')}
                              className="check-icon"
                              alt=""
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </LPOverridable>
                <div className="notifications-body">
                  <AppList
                    onInit={(list: any) => (this.list = list)}
                    model={NotificationModel}
                    renderWrapper={(items: React.ReactNode) => (
                      <ul style={{ padding: 0, marginBottom: 0 }}>{items}</ul>
                    )}
                    renderItem={(item: any) =>
                      this._renderNotificationItem(item)
                    }
                    renderNoItems={() => (
                      <div className="col-sm-12 well text-center">
                        <div style={{ margin: '20px', color: 'black' }}>
                          <em>No new notifications</em>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: { auth: { profile: any } }) => {
  return {
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(NotificationsPage);
