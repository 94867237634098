import React from 'react';
import { withRouter } from 'react-router';
import qs from 'qs';
import LoadingOverlay from './LoadingPage';
import _ from 'lodash';

class AppList extends React.Component {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {
      items: [],
      filter: this._getInitialFilter(props),
      order: this._getInitialOrder(props),
      pagination: {
        page: this._getInitialPage(props),
        total_pages: 0,
        limit: 20,
        total_items: 0
      },
      loadedTimes: 0
    };

    props.onInit(this);
  }

  /**
   * Called on mount
   */
  componentDidMount() {
    this._loadItems();
  }

  _getInitialFilter(props) {
    if (props.location.query.filter) {
      return props.location.query.filter;
    }

    return props.filter;
  }

  _getInitialOrder(props) {
    if (props.location.query.order) {
      return props.location.query.order;
    }
    return props.order || [];
  }

  /**
   * Loading off
   */
  _loadingOn() {
    this.setState({
      loading: true
    });
  }

  /**
   * Loading on
   */
  _loadingOff() {
    this.setState({
      loading: false
    });
  }

  /**
   * Get initial page
   *
   * @param {Object} props Passed props
   */
  _getInitialPage(props) {
    if (!props.parseUrl) return 1;
    return props.location.query.page ? props.location.query.page : 1;
  }

  /**
   * Get model
   */
  _getModel() {
    if (this.model) return this.model;

    this.model = new this.props.model();

    return this.model;
  }

  /**
   * Load items
   */
  async _loadItems() {
    // Show loader
    this._loadingOn();

    try {
      const listParams = this._getListParams();
      const results = await this._getModel().fetch(listParams);

      this.callback('afterDataLoaded', results.items);

      this.setState({
        loadedTimes: ++this.state.loadedTimes,
        items: results.items,
        pagination: results.pagination
      });
    } catch (e) {}

    setTimeout(() => {
      this._loadingOff();
    }, 100);
  }

  _reset() {
    return new Promise(resolve => {
      this.model = false;
      this.setState(
        {
          pagination: {
            ...this.state.pagination,
            page: 1
          }
        },
        () => {
          resolve(true);
        }
      );
    });
  }

  /**
   * Load items
   */
  async _loadMore() {
    // Show loader
    this._loadingOn();

    try {
      const listParams = this._getListParams();
      const results = await this._getModel().fetch(listParams);

      this.setState({
        loadedTimes: ++this.state.loadedTimes,
        items: [...this.state.items, ...results.items],
        pagination: results.pagination
      });
    } catch (e) {}

    setTimeout(() => {
      this._loadingOff();
    }, 100);
  }

  async _refresh(force = true) {
    if (force && this.props.parseUrl) {
      let pageUrl = `?${qs.stringify({
        ...this.props.location.query,
        page: this.state.pagination.page,
        filter: this.state.filter,
        order: this.state.order
      })}`;

      window.history.pushState('', '', pageUrl);
      this._loadItems();
      // history.push({
      //   search: qs.stringify({
      //     ...this.props.location.query,
      //     page: this.state.pagination.page,
      //     filter: this.state.filter,
      //     order: this.state.order
      //   })
      // })
    } else {
      // window.scrollTo(0, 0)
      this._loadItems();
    }
  }

  _getListParams() {
    const additionalParams = {};

    return {
      offset: (this.state.pagination.page - 1) * this.state.pagination.limit,
      limit: this.state.pagination.limit,
      ...additionalParams,
      ...this.props.params
    };
  }

  /**
   * Called on page changed
   *
   * @param {Integer} pageNumber New page number
   */
  _pageChanged(pageNumber) {
    const pagination = { ...this.state.pagination };
    pagination.page = pageNumber;
    this.setState(
      {
        pagination
      },
      () => {
        this._refresh();
      }
    );
  }

  /**
   * Get rows
   */
  _getRows() {
    if (this.state.loadedTimes > 0 && this.state.items.length == 0) {
      return this._renderNoItems();
    }

    if (this.state.loadedTimes === 0 && this.state.loading) {
      return (
        <div className={`app-list-item ${this.props.getRowClass()}`}>
          <LoadingOverlay />
        </div>
      );
    }

    const elements = [];

    this.state.items.forEach((item, index) => {
      elements.push(this.props.renderItem(item, index));
    });
    return elements;
  }

  _renderNoItems() {
    if (this.props.renderNoItems) {
      return this.props.renderNoItems();
    }

    return (
      <div className={`app-list-item ${this.props.getRowClass()}`}>
        No records
      </div>
    );
  }

  _getLoadingElement() {
    if (!this.state.loading || this.state.loadedTimes === 0) return null;
    return <LoadingOverlay />;
  }

  _getShowMoreElement() {
    if (!this.state.pagination.load_more) return null;
    return this.props.renderShowMore(this.state, () => {
      this.setState(
        {
          pagination: {
            ...this.state.pagination,
            page: this.state.pagination.page + 1
          }
        },
        () => {
          this._loadMore();
        }
      );
    });
  }

  /**
   * Update item in array
   * @param {Object} item Item
   */
  updateItem(item) {
    const { data } = this.state;
    const items = [...data.items];
    items.forEach((element, index) => {
      if (element.id === item.id) {
        items[index] = item;
      }
    });

    data.items = items;
    this.setState({
      data
    });
  }

  /**
   * Call callback in parent component
   *
   * @param {String} name Callback name
   * @param {Object} params Callback params
   */
  callback(name, ...params) {
    if (this.props[name] && typeof this.props[name] === 'function') {
      this.props[name](...params);
    }
  }

  /**
   * Render the component
   */
  render() {
    const rows = this._getRows();
    const loadingElement = this._getLoadingElement();
    const showMoreElement = this._getShowMoreElement();

    let innnerElement = null;

    if (this.props.renderWrapper) {
      innnerElement = this.props.renderWrapper(rows);
    } else {
      innnerElement = rows;
    }

    return (
      <div>
        {loadingElement}
        {innnerElement}
        {showMoreElement}
      </div>
    );
  }
}

// Default props
AppList.defaultProps = {
  renderShowMore: () => null,
  onInit: () => {},
  getTableColumns: () => [],
  getColumnActions: () => [],
  getCellClass: () => [],
  getRowClass: () => [],
  renderCell: () => false,
  message: '',
  params: {},
  parseUrl: true,
  paginate: true
};

export default withRouter(AppList);
