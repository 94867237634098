import React from 'react';
import { currency } from '../../../../util/NumberHelper';
import { fullDate } from '../../../../util/DateHelper';
import ModalService from '../../../../logic/services/ModalService';

const StandardTransaction = ({ transaction }) => {
  let amount = null;
  let refund = null;
  let failed = null;
  let feeElement = null;
  const isFX =
    transaction.originalAmountCurrency &&
    transaction.originalAmountCurrency !== transaction.amountCurrency;
  let fxElements = null;
  const extraClasses = [];

  if (transaction.type === 'refund') {
    extraClasses.push('refund');
    refund = (
      <div>
        <span style={{ padding: 0, color: '#c3251f' }}>refund</span>
      </div>
    );
  }
  if (transaction.status === 'declined') {
    extraClasses.push('declined');
    failed = (
      <div className="transaction-declined">
        <span style={{ padding: 0, color: '#c3251f' }}>declined</span>
      </div>
    );
  }

  extraClasses.push('transaction-status-' + transaction.status);

  const transactionAmount =
    transaction.amount !== 0 ? transaction.amount : transaction.fee;
  amount = (
    <span className="amount">
      {currency(transactionAmount, transaction.amountCurrency)}
      {refund}
      {failed}
    </span>
  );

  let fee = parseFloat(transaction.fee);
  if (parseFloat(transaction.fee)) {
    if (isFX && transaction.status === 'settled') {
      fee -= parseFloat(transaction.fxPadding);
    }
    feeElement = (
      <span
        style={{
          flex: 1,
          fontSize: 12,
          alignSelf: 'flex-end',
          textAlign: 'right'
        }}
      >
        <span className="fee-dash">-</span>{' '}
        {currency(fee, transaction.amountCurrency)} fee
      </span>
    );
  }

  if (transaction.amount === 0 && transaction.fee > 0) {
    feeElement = null;
  }

  if (isFX) {
    const rate = Math.abs(transaction.originalAmount / transaction.amount);
    fxElements = (
      <span
        style={{
          flex: 1,
          fontSize: 12,
          alignSelf: 'flex-end',
          textAlign: 'right'
        }}
      >
        {currency(
          transaction.originalAmount,
          transaction.originalAmountCurrency
        )}{' '}
        ({currency(1, true)} ={' '}
        {currency(rate, transaction.originalAmountCurrency)})
      </span>
    );
  }

  return (
    <li
      className={`notificationItem transactionItem statements ${extraClasses.join(
        ' '
      )}`}
      onClick={() =>
        ModalService.showModal('TRANSACTION_INFO', { transaction })
      }
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          flexGrow: 1
        }}
      >
        <span className="iconHolder" style={{ width: 40, height: 40 }}>
          <i
            className="fa fa-info"
            aria-hidden
            style={{ color: 'white', fontSize: 21 }}
          />
        </span>
        <span
          className="transaction-title-wrapper"
          style={{ display: 'flex', flexDirection: 'column', paddingLeft: 3 }}
        >
          <span className="transaction-title">{transaction.note}</span>
          <span className="transaction-date">
            {fullDate(transaction.transactionDate)}
          </span>
        </span>
      </div>
      <div className="transaction-amount-wrapper">
        {fxElements}
        {amount}
        {feeElement}
      </div>
    </li>
  );
};

export default StandardTransaction;
