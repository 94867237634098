import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class FaqModel extends BaseModel {
  getListUrl() {
    return 'support/faq/list';
  }

  async getFaq() {
    const faq = await Api.call(this.getListUrl(), {
      limit: 1000
    });
    return faq.data;
  }
}
