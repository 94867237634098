import { toast } from 'react-toastify';

export default class ToastHelper {
  static alertElement = null;

  static toastId = null;

  static setAlert(alertElement) {
    this.alertElement = alertElement;
  }

  static show(text, type = 'error') {
    let toastType = toast.TYPE.ERROR;

    if (type === 'error') {
      toastType = toast.TYPE.ERROR;
    } else if (type === 'success') {
      toastType = toast.TYPE.SUCCESS;
    }

    const options = {
      type: toastType,
      hideProgressBar: false,
      position: toast.POSITION.TOP_RIGHT,
      pauseOnHover: true,
      autoClose: 3000,
      closeOnClick: true,
      draggable: true
    };

    if (!toast.isActive(this.toastId)) {
      this.toastId = toast(text, options);
    }
  }

  static close() {
    toast.dismiss();
  }
}
