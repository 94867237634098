import React from 'react';

export default class PasswordService {
  static checkPassStrenght(password: any) {
    // Create an array and push all possible passwords that you want in password
    const matchedCase: any = [];
    matchedCase.push('[$@$!%*#?&]'); // Special Charector
    matchedCase.push('[A-Z]'); // Uppercase Alphabates
    matchedCase.push('[0-9]'); // Numbers
    matchedCase.push('[a-z]'); // Lowercase Alphabates

    // Check the conditions
    let ctr = 0;
    for (let i = 0; i < matchedCase.length; i++) {
      if (new RegExp(matchedCase[i]).test(password)) {
        ctr++;
      }
    }
    // Display it
    let color = '';
    let strength = '';
    switch (ctr) {
      case 0:
      case 1:
      case 2:
        strength = 'WEAK';
        color = 'red';
        break;
      case 3:
        strength = 'MEDIUM';
        color = 'orange';
        break;
      case 4:
        strength = 'STRONG';
        color = 'green';
        break;
    }
    // Do not show anything when the length of password is zero.
    if (password.length === 0) {
      color = '';
      strength = '';
    }
    return {
      strength,
      color
    };
  }

  static validatePassword(pswd: any) {
    if (pswd.length === 0) {
      throw new Error('Please enter a password');
    } else if (pswd.length > 30) {
      throw new Error('Password is too long, 30 characters max');
    } else if (pswd.length < 8) {
      throw new Error('Your password must be at least 8 characters long');
    } else if (pswd.search(/[a-z]/) === -1) {
      throw new Error('Password must include lowercase character');
    } else if (pswd.search(/[A-Z]/) === -1) {
      throw new Error('Password must include uppercase character');
    }

    let hasNumber = true;
    let hasChar = true;

    if (pswd.search(/\d/) === -1) {
      hasNumber = false;
    }

    if (pswd.search(/(?=.*?[#?!@$%^&*-])/) === -1) {
      hasChar = false;
    }

    if (!hasNumber && !hasChar) {
      throw new Error(
        'Password must include at least one number or special character'
      );
    }

    return true;
  }
}
