import BaseModel from '../../../logic/model/BaseModel';

export default class CurrencyModel extends BaseModel {
  /**
   * Get submit url
   */
  getListUrl() {
    return 'currencies/list';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {};
  }
}
