import BaseModel from '../../../logic/model/BaseModel';

export default class FxCalculatorModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'fx/calculate/ecb';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {};
  }
}
