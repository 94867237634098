import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import AppDatePickerField from './AppDatePickerField';
import moment from 'moment';
import AppValidationErrors from '../AppValidationErrors';

interface AppDatepickerPropsType {
  datePickerProps: any;
  [name: string]: any;
}

export const AppDatePicker: React.FC<any> = (props: AppDatepickerPropsType) => {
  const dateChanged = async date => {
    if (props.handler) {
      props.handler.handleInputChange(
        props.name,
        moment(date).format('YYYY-MM-DD')
      );
    }
  };

  return (
    <div className={props.className} style={props.style}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          inputVariant="outlined"
          style={{ width: '100%', margin: 0 }}
          format="dd/MM/yyyy"
          margin="normal"
          label={props.label}
          value={props.value}
          onChange={date => dateChanged(date)}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          {...props.datePickerProps}
        />
      </MuiPickersUtilsProvider>
      <AppValidationErrors name={props.name} handler={props.handler} />
    </div>
  );
};

export default AppDatePicker;
