import React from 'react';
import { LPOverridable } from '@launchpad/components';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import NumberHelper from '../../../util/NumberHelper';
import DateHelper from '../../../util/DateHelper';
import { AppIcon, AppButton } from '../../../index';
import { getCurrencySymbol } from '../../../util/CurrencyHelper';
import OverrideService from '../../../logic/services/overrides/OverrideService';

export default class LoadModal extends React.PureComponent<any, any> {
  render() {
    const { paymentDevice } = this.props;
    const { profile } = this.props;

    return (
      <ModalDialog id="LOAD_CARD_MODAL" show>
        <div
          className="transaction-modal"
          style={OverrideService.getOverrides()['custom-load-modal-style']}
        >
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                color: 'rgba(0,0,0,0.4)',
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <LPOverridable name="load-account-content" profile={profile}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                paddingBottom: 10
              }}
            >
              <h3 className="load-title">Load Card</h3>
              <p className="load-subtitle">
                Use the details below to load your card directly from your bank
                account
              </p>
              <div className="row justify-content-center align-items-center load-info">
                <div className="col-sm-6 column first-line">
                  <span>Payee</span>
                  <span>Sort code </span>
                  <span>Account No</span>
                  <span>IBAN </span>
                  <span>SWIFT/BICSWIFT</span>
                  <span>Currency</span>
                </div>
                <div className="col-sm-6 column second-line">
                  <span>Pannovate</span>
                  <span>12-34-56</span>
                  <span>1234567890</span>
                  <span>PK99BANK0123456789</span>
                  <span>AMADAGB11</span>
                  <span>{getCurrencySymbol(paymentDevice.currency)}</span>
                </div>
              </div>

              <div className="customer-no">
                <p>Your Customer no</p>
                <div className="ref-number-holder">
                  <span>{profile.customerReference}</span>
                </div>
                <h6>
                  Please include this number in the reference of your payment so
                  that we can ensure prompt credit to your account
                </h6>
              </div>
            </div>
          </LPOverridable>
          <LPOverridable name="send-request-btn">
            <div
              style={{
                display: 'flex',
                flex: 0.5,
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <AppButton customstyle={{ maxWidth: 450, maxHeight: 60 }}>
                SEND REQUEST
              </AppButton>
            </div>
          </LPOverridable>
        </div>
      </ModalDialog>
    );
  }
}
