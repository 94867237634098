export default function modals(state = [], action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return [...state, action.payload];
    case 'HIDE_MODAL':
      if (!action.payload.id) return [];
      return state.filter(x => x.id != action.payload.id);
  }
  return state;
}
