import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import ModalService from '@launchpad/logic/services/ModalService';
import CardService from '@launchpad/modules/card/CardService';
import LoadingHelper from '@launchpad/util/LoadingHelper';
import AuthService from '@launchpad/logic/services/AuthService';
import CardModel from '@launchpad/modules/card/models/CardModel';
import { AppButton, AppIcon, ModalDialog } from '@launchpad/components';
import ToastHelper from '@launchpad/util/ToastHelper';

export const NAME = 'ACTIVATE_CARD';

class KabnActivateCardModal extends React.Component<any, any> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {
      show: true,
      digits: ['', '', '', '', '', '', '', '', '', '', '', '', '']
    };
  }

  onClose() {
    ModalService.hideModal('ACTIVATE_CARD');
  }

  nextFocus(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const digits = [...this.state.digits];

    digits[index] = e.target.value;

    const regex = /^[0-9\b]+$/;

    const num = regex.test(digits[index]);

    if (e.target.value === '' || num) {
      this.setState({
        digits
      });
    } else {
      return;
    }

    if (e.target.value.length === 1) {
      $(e.target)
        .next()
        .focus();
    } else {
      $(e.target)
        .prev()
        .focus();
    }
  }

  _activateCard() {
    const digits = this.state.digits.filter(x => x !== '');

    if (digits.length < 13) {
      ToastHelper.show(
        'Please enter the 13 digit number found on the back of your card!'
      );
      return;
    }

    const cardNumber = digits.slice(0, 13).join('');

    const card = CardService.getDefaultCard(this.props.paymentDevices);
    // Order card here
    LoadingHelper.executeAction(
      () => {
        return this._actualActivateCard(card, cardNumber);
      },
      {
        successMessage: 'Your card is activated!',
        loadingMessage: 'Activating your card...',
        failureMessage: 'Card activation failed!',
        beforeClose: (status: string) => {
          if (status !== 'failure') {
            AuthService.refreshAuth();
            this.onClose();
          }
        }
      }
    );
  }

  async _actualActivateCard(card: any, cardNumber: string) {
    const cardModel = new CardModel();
    const result = await cardModel.activatePaymentDeviceWithAllPan(
      card,
      cardNumber
    );

    if (result) {
      this.onClose();
      return true;
    }

    throw new Error('Card activation failed');
  }

  render() {
    const user = this.props.profile;

    if (!user) return null;

    return (
      <ModalDialog show={this.state.show} onClose={this.onClose.bind(this)}>
        <div className="transaction-modal">
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                color: 'rgba(0,0,0,0.4)',
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal()}
            />
          </div>
          <div className="inner-container">
            <h1>Card Activation</h1>
            <p>
              Please enter the 13 digit number found on the back of your card
            </p>
            <img
              src={require('../../../../assets/img/cards/card-front-blue.png')}
              alt="activate-card"
              style={{ margin: '20px auto 0 auto', maxWidth: 350 }}
            />
            <div className="container" style={{ maxWidth: 1000 }}>
              <div className="row" style={{ justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                  <div
                    className="input-wrapper"
                    style={{
                      flexDirection: 'row',
                      marginTop: 30,
                      marginBottom: 30
                    }}
                  >
                    {this.state.digits.map((digit, index) => (
                      <input
                        // eslint-disable-next-line react/no-array-index-key
                        key={`digit${index}`}
                        value={this.state.digits[index]}
                        type="text"
                        maxLength={1}
                        className="input-pin inputs"
                        onChange={e => this.nextFocus(e, index)}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={index === 0}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20
                }}
              >
                <AppButton onClick={() => this._activateCard()}>
                  ACTIVATE CARD
                </AppButton>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}
const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};

export default connect(mapStateToProps)(KabnActivateCardModal);
