import React from 'react';
import { connect } from 'react-redux';
import CardService from '@launchpad/modules/card/CardService';

interface CardImageProps {
  customstyle?: any;
  onClick?: () => void;
  paymentDevices?: any;
}

class CardImage extends React.Component<CardImageProps> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  /**
   * Render
   */
  render() {
    const card = CardService.getDefaultCard(this.props.paymentDevices);

    let pan = null;
    if (card && card.pin) {
      pan = card.pan.replace(/(.{4})/g, '$1 ').trim();
    }

    return (
      <div
        className="position-relative"
        style={this.props.customstyle}
        onClick={this.props.onClick}
      >
        <img
          src={require('../../../assets/img/cards/card-front-blue.png')}
          className="card-page-card-image"
          alt="card"
        />
        {card.isLive && !card.locked && (
          <div
            style={{
              color: '#d3d4d5',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 7,
              padding: 15,
              fontFamily: 'Credit-Card-Font'
            }}
            className="d-flex justify-content-end w-100 h-100 flex-column"
          >
            <h6 style={{ padding: '0 0 5px 30px', fontSize: 18 }}>{pan}</h6>
            {/* <p style={{ padding: '0 0 5px 145px', fontSize: 14 }}>
              {card.expires}
            </p> */}
            <h5 style={{ padding: '0 0 0px 30px', textTransform: 'uppercase' }}>
              {card.emboss}
            </h5>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: {
  paymentDevices: any;
  auth: { profile: any };
}) => {
  return {
    paymentDevices: state.paymentDevices,
    profile: state.auth.profile
  };
};
export default connect(mapStateToProps)(CardImage);
