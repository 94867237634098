import React, { Component } from 'react';
import ReactModal from 'react-modal';
import ModalService from '../../logic/services/ModalService';

export default class ModalDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
    this.handleCloseModal = this.handleCloseModal.bind(this);

}
  handleCloseModal() {
    if(this.props.id) {
      ModalService.hideModal(this.props.id)
    }
    this.props.onClose();
  }

  componentWillMount() {
    if(this.props.inBody) {
      ReactModal.setAppElement('body');
    }
  }

  componentWillUnmount() {

  }

  getParent(props) {
    return props.inBody ? document.body : document.querySelector('#modalHolder');
  }

  render() {
    let props = this.props;

    return (
      <ReactModal
        isOpen={this.props.show}
        onRequestClose={this.handleCloseModal}
        className="react-modal-content"
        overlayClassName="react-modal-overlay"
        portalClassName="react-modal-portal"
        shouldReturnFocusAfterClose={true}
        role="dialog"
        parentSelector={() => this.props.inBody ? document.body : document.querySelector('#modalHolder')}
        style={this.props.style}
      >
        {this.props.children}
      </ReactModal>
    )
  }
}

ModalDialog.defaultProps = {
  style: {},
  onClose: () => {}
}
