import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

interface KabnButtonProps {
  isLoading?: boolean;
  inverted?: boolean;
  customstyle?: any;
  onClick?: () => void;
}

export default class KabnButton extends React.Component<KabnButtonProps> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  /**
   * Get button or spinner
   */
  _getButtonContent() {
    if (this.props.isLoading) {
      return (
        <CircularProgress
          className={`circle-progress ${this.props.inverted ? 'light' : ''}`}
          size={30}
        />
      );
    }

    return this.props.children;
  }

  /**
   * Render
   */
  render() {
    return (
      <div
        className={`gradient-button ${
          this.props.inverted ? 'gradient-btn-custom' : ''
        }`}
        style={this.props.customstyle}
      >
        <Button
          variant="contained"
          className={this.props.inverted ? 'custom-inverted-btn' : 'custom-btn'}
          {...this.props}
        >
          {this._getButtonContent()}
        </Button>
      </div>
    );
  }
}
