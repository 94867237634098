import BaseModel from '../../../logic/model/BaseModel';

export default class TransactionModel extends BaseModel {
  getListUrl() {
    return 'account/transactions';
  }

  // async getItems(period, type, limit = null) {
  //   let url = null;
  //   switch(type) {
  //     case 'all':
  //       url = 'account/transactions';
  //     break;
  //     case 'country':
  //       url = 'account/transactions/countries';
  //     break;
  //     case 'merchant':
  //       url = 'account/transactions/merchants';
  //     break;
  //     case 'category':
  //       url = 'account/transactions/categories';
  //     break;
  //   }

  //   let result = await Api.call(url, {
  //     limit
  //   });
  //   return result.data.items;
  // }
}
