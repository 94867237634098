import React, { Component } from 'react';

export default class SwitchButton extends Component<any, any> {
  static defaultProps: { onChange: () => void; };
  constructor(props: { isChecked: any; }) {
    super(props);

    this.state = {
      isChecked: props.isChecked
    }
    this._handleChange = this._handleChange.bind(this);
  }

  static getDerivedStateFromProps(props: { isChecked: any; }, state: any) {
    let stateChange = {} as any;
    if (typeof props.isChecked != 'undefined') {
      stateChange.isChecked = props.isChecked;
    }
    return stateChange;
  }

  componentDidMount() {
    this.setState({ isChecked: this.props.isChecked });
  }

  _handleChange() {
    let val = !this.state.isChecked
    this.setState({ isChecked: val }, () => {
      this.props.onChange(val)
    });
  }


  render() {
    let text = null as any;
    if (this.props.text) {
      text = <p style={{ paddingRight: 10, fontSize: 12, fontWeight: 'lighter', margin: 0 }}>{this.state.isChecked ? 'ON' : 'OFF'}</p>;
    }
    return (

      <div className="switch-container">
        {text}
        <label style={{ margin: 0, display: 'block' }}>
          <input checked={this.state.isChecked} onChange={this._handleChange} className="switch" type="checkbox" />
          <div>
            <span className="icon icon-toolbar grid-view"></span>
            <span className="icon icon-toolbar ticket-view"></span>
            <div></div>
          </div>
        </label>

      </div>

    )
  }
}


SwitchButton.defaultProps = {
  onChange: () => { }
}
