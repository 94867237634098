import React, { Component } from 'react'
import { Route } from 'react-router-dom';
import LoginPage from '../../modules/auth/pages/LoginPage';
import WelcomePage from '../../modules/auth/pages/WelcomePage';
import ForgotPassword from '../../modules/auth/pages/ForgotPassword';
import { AppRoute } from '../../index';
import { Switch } from 'react-router-dom';

export default class PublicLayout extends Component<any, any> {
  match: any;
  constructor(props: any) {
    super(props);
    this.match = props.match;
  }

  componentDidMount() {
    let token = localStorage.getItem('token');
    if(token) {
      // this.props.history.push('/dashboard')
    }
  }
  
  render() {
    return (
      <div className="public-layout">
          {/* <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/" component={WelcomePage} /> */}
          <Switch>
            <AppRoute path="/forgot-password" component={ForgotPassword} />
            <AppRoute path="/login" component={LoginPage} />
            <AppRoute path="/" component={WelcomePage} />
          </Switch>
      </div>
    )
  }
}
