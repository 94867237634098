/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { AppButton } from '@launchpad/components/crud/AppButton';
import Api from '@launchpad/logic/api/Api';
import DateHelper from '../../../util/DateHelper';
import { AppIcon } from '../../../index';
import NotificationModel from '../models/NotificationModel';
import ModalDialog from '../../../components/modal/ModalDialog';
import { OverrideService } from '../../../logic/services';

export default class NotificationListItem extends React.PureComponent<
  any,
  any
> {
  /**
   * Constructor
   *
   * @param props
   */
  constructor(props: any) {
    super(props);
    this.state = {
      show: false,
      notificationTitle: '',
      notificationText: ''
    };
  }

  onClose = () => {
    this.setState(
      {
        show: false,
        notificationTitle: '',
        notificationText: ''
      },
      () => {
        this.props.closeNotification();
      }
    );
  };

  showModal(data: any) {
    this.setState(
      {
        notificationTitle: data.notificationTitle,
        notificationText: data.notificationText,
        show: true
      },
      () => {
        this._markNotificationAsRead(data);
      }
    );
  }

  /**
   * Mark notification as read
   *
   * @param {object} notification Notification to mark as read
   */
  async _markNotificationAsRead(notification) {
    try {
      await new NotificationModel().readNotification(notification.id);
    } catch (e) {}
  }

  render() {
    const { notification } = this.props;

    return (
      <>
        <li
          className="notificationItem"
          style={{ cursor: 'pointer' }}
          onClick={() => this.showModal(notification)}
        >
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {!notification.read ? (
              <i
                className="fa fa-circle"
                aria-hidden
                style={{
                  color:
                    OverrideService.getOverrides()['unread-circle-color'] ||
                    '#6425AB',
                  opacity: 0.5,
                  fontSize: 10,
                  alignSelf: 'flex-start'
                }}
              />
            ) : null}
          </span>
          <div style={{ flex: 1, paddingRight: 70 }}>
            <p>{notification.notificationTitle}</p>
            <p>{notification.notificationPreview}</p>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignSelf: 'flex-end'
            }}
          >
            {/* <span className='iconHolder notification' style={{ minWidth: 40, flex: 1 }}>
            <div style={{ background: 'white', width: 30, height: 30, borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <AppIcon name={NotificationModel.getIcon(notification)} style={{ fontSize: 25 }} />
            </div>
          </span> */}
            <span style={{ fontSize: 12, color: '#656565' }}>
              {DateHelper.date(notification.created)}
            </span>
          </div>
        </li>
        <ModalDialog show={this.state.show} onClose={this.onClose.bind(this)}>
          <div
            className="transaction-modal"
            style={{ maxHeight: 500, minHeight: 400 }}
          >
            <div className="close-btn">
              <AppIcon
                name="fa.times"
                style={{
                  fontSize: 24,
                  color: 'rgba(0,0,0,0.4)',
                  cursor: 'pointer'
                }}
                onClick={() => this.onClose()}
              />
            </div>
            <div
              className="inner-container"
              style={{ justifyContent: 'space-between', width: '100%' }}
            >
              <div style={{ width: '100%' }}>
                <h1>{this.state.notificationTitle}</h1>
                <div className="container" style={{ maxWidth: 1000 }}>
                  <div className="row pt-3" style={{ justifyContent: 'start' }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>{this.state.notificationText}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 20,
                  width: '100%'
                }}
              >
                <AppButton onClick={() => this.onClose()}>CLOSE</AppButton>
              </div>
            </div>
          </div>
        </ModalDialog>
      </>
    );
  }
}
