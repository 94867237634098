import React from 'react';
import { connect } from 'react-redux';
import LoadingHelper from '../../util/LoadingHelper';
import LoadingModal from './components/LoadingModal';
import InfoModal from './components/InfoModal';
import StatementModals from '../statements/StatementModals';
import LoadCardModal from '../dashboard/modals/LoadCardModal';
import CardModals from '../card/CardModals';
import ProfileModals from '../profile/ProfileModals';

const APP_MODALS = {
  ...CardModals,
  ...LoadCardModal,
  ...StatementModals,
  ...ProfileModals,
  INFO_MODAL: InfoModal

  // LOADING_MODAL: LoadingModal
};

class Modals extends React.Component {
  render() {
    const { modals } = this.props;

    const children = [];

    modals.forEach((modal, index) => {
      if (APP_MODALS[modal.id]) {
        const SpecificModal = APP_MODALS[modal.id];
        const props = modal.props ? modal.props : {};
        children.push(<SpecificModal key={`modal${index}`} {...props} />);
      }
    });

    return (
      <div>
        {children}
        <LoadingModal
          inBody
          ref={element => LoadingHelper.setLoadingModal(element)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    modals: state.modals
  };
};
export default connect(mapStateToProps, null)(Modals);
