export default class LoadingHelper {
  static modal = null;

  static setLoadingModal(loadingModal) {
    this.modal = loadingModal;
  }

  static executeAction(promise, options = {}, modal = null) {
    const currentModal = modal || this.modal;

    if (currentModal) {
      return currentModal.executeAction(promise, options);
    }

    return null;
  }

  static success(message, options = {}, modal = null) {
    const currentModal = modal || this.modal;

    if (currentModal) {
      return currentModal.success(message, options);
    }

    return null;
  }
}
