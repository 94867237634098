import BaseModel from '../../../logic/model/BaseModel';
import Api from '../../../logic/api/Api';

export default class NotificationModel extends BaseModel {
  getListUrl() {
    return 'notifications/list';
  }

  static getIcon(item) {
    const predefinedIcons = {
      success: 'fa.check',
      info: 'fa.info',
      success: 'fa.check',
      warning: 'fa.exclamation-triangle',
      done: 'fa.check'
    };

    const icon = predefinedIcons[item.notificationIcon]
      ? predefinedIcons[item.notificationIcon]
      : item.notificationIcon;
    return icon;
  }

  async seenNotifications() {
    return Api.call('notifications/seen');
  }

  async readNotification(id) {
    return Api.call('notifications/read', {
      id
    });
  }
}
