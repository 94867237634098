import moment from 'moment';

export function date(dateValue, format = 'DD/MM/Y') {
  return moment(dateValue).format(format);
}
export function time(dateValue, format = 'H:m') {
  return moment(dateValue).format(format);
}
export function fullDate(dateValue, format = 'DD MMM YYYY, HH:mm') {
  return moment(dateValue).format(format);
}

export function getHumanized(dateValue) {
  return date(dateValue, 'ddd, MMM Do');
}

export function add(dateValue, count, intervals) {
  if (dateValue == null) {
    // eslint-disable-next-line no-param-reassign
    dateValue = moment();
  }
  return moment(dateValue).add(count, intervals);
}

export function year(dateValue = null) {
  if (!dateValue) {
    return moment().year();
  }

  return moment(dateValue).year();
}

export function expiryDate(dateValue) {
  return date(dateValue, 'MM/YYYY');
}

export function toApiDate(dateValue, fromFormat = 'DD/MM/YYYY') {
  return moment(dateValue, fromFormat).format('YYYY-MM-DD');
}

/**
 * TODO: Return greeting by time
 */
export function getGreeting() {
  const curHr = new Date().getHours();

  if (curHr < 12) {
    return 'Good morning';
  } else if (curHr < 18) {
    return 'Good afternoon';
  }
  return 'Good evening';
}

export default {
  date,
  time,
  fullDate,
  getHumanized,
  add,
  expiryDate,
  toApiDate,
  getGreeting,
  year
};
