import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CustomIcon from '@launchpad/components/elements/icon/CustomIcon';
import MAIN_COLOR from '../../../styles/_variables.scss';
import { OverrideService } from '../../../logic/services';

export default class SideMenuItem extends Component {
  /**
   * Set initial state in constructor
   */
  constructor() {
    super();
    this.state = {
      opened: false,
      activeHover: OverrideService.getThisOrOverridenColors().main_color
    };
  }

  /**
   * Called on link click
   */
  linkClick() {
    this.setState({
      opened: !this.state.opened
    });
  }

  /**
   * Render function
   *
   * @returns {XML}
   */
  render() {
    // Build icon
    const icon = `fa fa-${this.props.icon}`;
    const { customIcon } = this.props;

    // Build children
    const children = [];
    if (this.props.children) {
      this.props.children.map((child, index) => {
        const key = `side-menu-item-${index}`;
        children.push(<SideMenuItem {...child} key={key} />);
      });
    }

    // Get children components
    let childPart = '';
    const { path } = this.props;
    if (children.length) {
      childPart = <ul className="sub-nav">{children}</ul>;
    }

    // Check if item is active
    const isActive = this.isPathActive(this.props);

    // Check if item should be opened
    const opened = true;
    // if(isActive) {
    //   opened = true;
    // }

    let innerComponent = null;

    if (path) {
      innerComponent = (
        <Link
          to={path}
          onClick={this.linkClick.bind(this)}
          onMouseEnter={() => {
            this.setState({
              activeHover: 'white'
            });
          }}
          onMouseLeave={() => {
            this.setState({
              activeHover: OverrideService.getThisOrOverridenColors().main_color
            });
          }}
        >
          {this.props.customIcon ? (
            <CustomIcon
              name={customIcon}
              color={this.state.activeHover}
              size={40}
            />
          ) : (
            <i
              data-toggle="tooltip"
              data-placement="right"
              title={this.props.label}
              className={icon}
              aria-hidden="true"
            />
          )}

          {/* <span>{this.props.label}</span> */}
        </Link>
      );
    } else {
      innerComponent = (
        <a href="javascript:void(0)" onClick={this.linkClick.bind(this)}>
          {this.props.customIcon ? (
            <CustomIcon
              name={customIcon}
              color={this.state.activeHover}
              size={40}
            />
          ) : (
            <i
              data-toggle="tooltip"
              data-placement="right"
              title={this.props.label}
              className={icon}
              aria-hidden="true"
            />
          )}
          {/* <span>{this.props.label}</span> */}
        </a>
      );
    }

    // Render item
    return (
      <li className={isActive ? 'active' : null}>
        {isActive ? <div className="active-blue-line" /> : null}
        {innerComponent}
        {opened ? childPart : null}
      </li>
    );
  }

  /**
   * Check if path is active
   *
   * @param item
   * @returns {boolean}
   */
  isPathActive(item) {
    // Get current path from context
    // let currentPath = this.context.router.route.location.pathname;
    const currentPath = window.location.pathname;

    // If paths same?
    if (currentPath === item.path) {
      return true;
    }

    // Check if some child is active
    if (item.children) {
      for (let i = 0; i < item.children.length; i++) {
        const childActive = this.isPathActive(item.children[i]);

        if (childActive) {
          return true;
        }
      }
    }

    // Default false
    return false;
  }
}

SideMenuItem.contextTypes = {
  router: PropTypes.object
};
