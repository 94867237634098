import React from 'react';
import Config from '../../config';

export default class AppImage extends React.Component<any, any> {

  render() {
    let source = this.getSource();

    let props = {
      ...this.props,
      src: source
    };

    return (<img {...props} style={this.props.style} />)
  }

  getSource() {
    let source = this.props.src;

    if (!source || source.length === 0) {
      return require('../../assets/no-image.png');
    }

    if (!source.substring) {
      return source;
    }

    if (source.substring(0, 8) == '/static/') {
      return source;
    }

    if (source[0] == '/') {
      let location = new URL(Config.url);
      return location.origin + source;
    }

    return source;
  }
}
