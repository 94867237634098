import React from 'react';
import { currency } from '../../../../util/NumberHelper';
import { date } from '../../../../util/DateHelper';
import LPOverridable from '../../../../components/overrides/LPOverridable';

const MerchantTransaction = ({ transaction }) => {
  let amount = null;
  if (transaction.type === 'spend' && transaction.amount !== 0) {
    amount = (
      <span style={{ fontSize: 20, alignSelf: 'flex-end' }}>
        -£{currency(Math.abs(transaction.amount, true))}
      </span>
    );
  } else {
    amount = (
      <span style={{ fontSize: 20, alignSelf: 'flex-end' }}>
        {currency(transaction.amount, true)}
      </span>
    );
  }

  const defaultImage = (
    <LPOverridable name="transaction.merchant.image">
      <img
        style={{ width: 40, height: 'auto' }}
        src={require('../../../../assets/no-image.png')}
        alt="No-img"
      />
    </LPOverridable>
  );

  return (
    <li className="notificationItem transactionItem statements ">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          flexGrow: 1
        }}
      >
        {!transaction.image ? (
          defaultImage
        ) : (
          <img
            style={{ width: 40, height: 'auto', borderRadius: 20 }}
            src={transaction.image}
            alt="Merchant"
          />
        )}
        <span
          className="transaction-title-wrapper"
          style={{ display: 'flex', flexDirection: 'column', paddingLeft: 3 }}
        >
          <span className="transaction-title">{transaction.name}</span>
          {/* <span className="transaction-date">
            {date(transaction.transactionDate)}
          </span> */}
        </span>
      </div>
      <div className="transaction-amount-wrapper">{amount}</div>
    </li>
  );
};

export default MerchantTransaction;
