import React from 'react';
import ModalDialog from '../../../components/modal/ModalDialog';
import ModalService from '../../../logic/services/ModalService';
import NumberHelper from '../../../util/NumberHelper';
import { AppIcon } from '../../../index';

export default class FxInfoModal extends React.PureComponent {
  /**
   * Constructor
   *
   * @param {Object} props
   */
  constructor(props) {
    super(props);

    // Initial state
    this.state = {};
  }

  render() {
    const { transaction } = this.props;

    const transactionFees = transaction.fxFee;

    return (
      <ModalDialog id="FX_INFO" show>
        <div className="transaction-modal">
          <div className="close-btn">
            <AppIcon
              name="fa.times"
              style={{
                fontSize: 24,
                color: 'rgba(0,0,0,0.4)',
                cursor: 'pointer'
              }}
              onClick={() => ModalService.hideModal('FX_INFO')}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              minHeight: 550,
              width: '100%',
              marginTop: 10
            }}
          >
            <div
              style={{
                width: '100%',
                padding: 20,
                backgroundColor: 'rgba(173, 138, 78, 0.7)'
              }}
            >
              <h5 style={{ color: 'white' }}>Conversion Summary</h5>
            </div>
            <div
              style={{
                width: '100%',
                padding: 20,
                textAlign: 'center'
              }}
            >
              <h6 className="text-center" style={{ fontWeight: 'bold' }}>
                Your Card Currency Amount
              </h6>
              <h2
                className="text-center"
                style={{ fontWeight: 'bold', padding: '15px 0' }}
              >
                {NumberHelper.currency(
                  transactionFees.destinationAmount,
                  transactionFees.destinationCurrency
                )}
              </h2>
              <p>Effective Transaction Conversion Rate Including All Fees*:</p>
              <p style={{ fontWeight: 'bold' }}>
                1 {transactionFees.sourceCurrency} ={' '}
                {transactionFees.effectiveRate}{' '}
                {transactionFees.destinationCurrency}
              </p>
            </div>
            <div
              style={{
                width: '100%',
                padding: 20,
                backgroundColor: 'rgba(173, 138, 78, 0.7)',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <h5 style={{ color: 'white' }}>Transaction Fees</h5>
            </div>
            <div
              style={{
                textAlign: 'left',
                width: '100%',
                fontSize: 14,
                padding: '10px 0'
              }}
            >
              <span>
                Non-Euro Prepaid Card Purchases are subject to a{' '}
                {transactionFees.bankFeePct}% fee on the Mastercard conversion
                value for this transaction:
              </span>
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {transactionFees.schemeAmount}{' '}
                {transactionFees.destinationCurrency} (1{' '}
                {transactionFees.sourceCurrency} = {transactionFees.schemeRate}{' '}
                {transactionFees.destinationCurrency})
              </span>
              <br />
              <br />
              <span style={{ paddingTop: 10 }}>
                The published European Central Bank (ECB) conversion rate for
                this currency pair on your selected transaction date is:
              </span>
              <br />
              <span style={{ fontWeight: 'bold' }}>
                1 {transactionFees.destinationCurrency} ={' '}
                {transactionFees.ecbRate} {transactionFees.sourceCurrency}
              </span>
              <br />
              <br />
              <span style={{ paddingTop: 10 }}>
                Fee for this transaction:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {NumberHelper.currency(
                    transactionFees.bankFee,
                    transactionFees.destinationCurrency
                  )}
                </span>
              </span>
              <br />
              <span>
                Mastercard Fee for this transaction:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {NumberHelper.currency(
                    transactionFees.schemeFee,
                    transactionFees.destinationCurrency
                  )}
                </span>
              </span>
              <br />
              <br />
              <span>
                Total Fees:{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {NumberHelper.currency(
                    transactionFees.totalFee,
                    transactionFees.destinationCurrency
                  )}
                </span>
              </span>
              <br />
              <br />
              <span style={{ paddingTop: 10 }}>
                These fees represent a{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {transactionFees.totalFeePct}%
                </span>{' '}
                increase on the ECB conversion value for this transaction.
              </span>
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  }
}
