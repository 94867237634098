import React from 'react';
import CalculatorComponent from '../components/CalculatorComponent';

export class FxCalculator extends React.Component<any, any> {
  render() {
    return (
      <div className="appContainer top-container">
        <div className="inner-container" style={{ width: '100%' }}>
          <div
            className="profile-container fx-calculator"
            style={{ maxWidth: 900, width: '100%' }}
          >
            <h1>Exchange Fee Calculator</h1>
            <div style={{ marginTop: 30, textAlign: 'center' }}>
              <CalculatorComponent />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FxCalculator;
