const CustomNavigation = [
  {
    label: 'Dashboard',
    customIcon: 'speedometer',
    path: '/'
  },
  {
    label: 'Card',
    customIcon: 'credit-card-2',
    path: '/card'
  },
  {
    label: 'Statements',
    customIcon: 'graph-3',
    path: '/statements'
  },
  {
    label: 'Profile',
    customIcon: 'user',
    path: '/profile'
  }
];

export default CustomNavigation;
