import React from 'react';
import IcomoonReact from 'icomoon-react';
// eslint-disable-next-line import/extensions
import iconSet from './selection.json';
// eslint-disable-next-line import/no-cycle
interface IconProps {
  color?: any;
  size?: string | number;
  name: string;
  iconSet?: Record<string, any>;
  className?: string;
}

const CustomIcon: React.FC<IconProps> = props => {
  const { color, size, name, className = '' } = props;

  return (
    <IcomoonReact
      className={className}
      iconSet={iconSet}
      color={color || 'white'}
      size={size || '26px'}
      icon={name}
    />
  );
};

export default CustomIcon;
