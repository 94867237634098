import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import ModalReducers from '../../modules/modals/ModalReducers';
import CardReducers from '../..//modules/card/CardReducers';
import AccountReducers from '../../modules/account/AccountReducers';
import WalletReducers from '../../modules/wallet/WalletReducers';
import AuthReducers from '../../modules/auth/AuthReducers';
import { MEMBER_LOGIN_TRIGGER, MEMBER_TOKEN_LOADED } from '../../modules/auth/AuthActions';
import { APP_MOUNTED } from './AppActions';
import { history } from './AppStore';

function network(state = null, action) {
  let newState = {
    error: null
  };

  switch (action.type) {
    case 'NETWORK_ERROR':
      newState.error = action.payload.message;
      break;
    default:
  }
  return newState;
}

function ready(state = false, action) {
  switch (action.type) {
    case MEMBER_LOGIN_TRIGGER:
      return true;
    case MEMBER_TOKEN_LOADED:
      return true;
    case APP_MOUNTED:
      return true;
  }
  return state;
}

const createRootReducer = (history) => ({
  router: connectRouter(history),
  ready: ready,
  network: network,
  modals: ModalReducers,
  auth: AuthReducers,
  accounts: AccountReducers,
  paymentDevices: CardReducers,
  wallets: WalletReducers,
});

export default createRootReducer;
