import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { OverrideService } from '../../logic/services';

interface ButtonProps {
  isLoading?: boolean;
  inverted?: boolean;
  customstyle?: any;
  onClick?: () => void;
}

export class AppButton extends React.Component<ButtonProps> {
  _getButtonContent() {
    if (this.props.isLoading) {
      return <CircularProgress className="circle-progress" size={20} />;
    }

    return this.props.children;
  }

  render() {
    return (
      <div
        className={`gradient-button ${
          this.props.inverted ? 'gradient-btn-custom' : ''
        }`}
        style={this.props.customstyle}
      >
        <Button
          variant="contained"
          className={this.props.inverted ? 'custom-inverted-btn' : 'custom-btn'}
          {...this.props}
        >
          {this._getButtonContent()}
        </Button>
      </div>
    );
  }
}

// export default AppButton;
export default OverrideService.thisOrOverridenComponent(AppButton, 'ui.button');
