import BaseModel from '../../../logic/model/BaseModel';

export default class SendMoneyModel extends BaseModel {

    /**
     * Get submit url
     */
    getSubmitUrl() {
        return 'auth/login';
    }

    /**
     * Get validation rules
     */
    getValidationRules() {
      return {
        email: {
          presence: {
            message: '^Please enter an email address'
          },
          email: {
            message: '^Please enter a valid email address'
          }
        },
        name: {
          presence: {
            message: '^Please enter a Full Name of Recipient'
          },
        },
        refNumber: {
          presence: {
            message: '^Please enter a Recipient Reference Number'
          },
        },
      }
    }
}
