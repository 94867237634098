import PasswordService from '@launchpad/logic/services/PasswordService';
import BaseModel from '../../../logic/model/BaseModel';

export default class ChangePasswordFormModel extends BaseModel {
  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'auth/password-change';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      // oldPassword: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter old password'
      //   }
      // }
    };
  }

  customValidation(record) {
    const errors = [];

    const pswd = record.password;
    const repeatedPswd = record.repeatedPassword;

    try {
      const result = PasswordService.validatePassword(pswd);
    } catch (e) {
      errors.push({
        field: 'password',
        message: e.message
      });
    }

    if (repeatedPswd.length === 0) {
      errors.push({
        field: 'repeatedPassword',
        message: 'Please confirm password'
      });
    } else if (pswd !== repeatedPswd && repeatedPswd !== 0) {
      errors.push({
        field: 'repeatedPassword',
        message: "Password doesn't match"
      });
    }

    return errors;
  }
}
