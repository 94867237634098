import React from 'react';
import { AppIcon, SwitchButton, AppButton } from '../../index';
import LoadingHelper from '../../util/LoadingHelper';
import CardModel from '../../modules/card/models/CardModel';
import AuthService from '../../logic/services/AuthService';
import ModalDialog from '../modal/ModalDialog';

class LockCardButton extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  _changeCardLockStatus(status: any) {
    const { card } = this.props;
    LoadingHelper.executeAction(
      () => {
        return new CardModel().freezePaymentDevice(card.id, status);
      },
      {
        successMessage: status
          ? 'Your card is locked'
          : 'Your card is now unlocked',
        loadingMessage: status ? 'Locking card...' : 'Unlocking card...',
        failureMessage: 'An error ocurred, please try again later',
        manualClose: true,
        renderIcon: (state: string, params: any) => {
          if (state == 'loading') return null;
          return status ? (
            <AppIcon name="fa.lock" style={{ fontSize: 150 }} />
          ) : (
            <AppIcon name="fa.unlock-alt" style={{ fontSize: 150 }} />
          );
        },
        beforeClose: () => {
          AuthService.refreshAuth();
        }
      }
    );
  }

  render() {
    const { card } = this.props;
    const { profile } = this.props;
    let lockElement = null as any;

    if (card.locked) {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(false)}
          className="send-load-btn-wrapper"
        >
          <div className="send-load-icon-wrapper">
            <AppIcon
              name="fa.unlock-alt"
              style={{ fontSize: 24, color: 'white' }}
            />
          </div>
          Unlock card
        </div>
      );
    } else if (card.status == 'ok') {
      lockElement = (
        <div
          onClick={() => this._changeCardLockStatus(true)}
          className="send-load-btn-wrapper"
        >
          <div className="send-load-icon-wrapper">
            <AppIcon name="fa.lock" style={{ fontSize: 24, color: 'white' }} />
          </div>
          Lock card
        </div>
      );
    }

    return <div className="button-wrapper">{lockElement}</div>;
  }
}

export default LockCardButton;
