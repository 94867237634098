import React from 'react';
import _ from 'lodash';
import { AppConfig } from '../../../config/types';
import Config from '../../../config';
import appStore from '../../store/AppStore';
import colors from '../../../styles/_variables.scss';

export default class OverrideService {
  static configuration: any;
  static overrides: any;

  /**
   * Refresh auth
   */
  static storeConfiguration(configuration: any) {
    this.configuration = configuration;
    Config.merge(this.getConfig());

    // Inject sagas
    if (this.configuration && this.configuration.sagas) {
      appStore.injectSaga('custom', this.configuration.sagas);
    }

    // Inject reducers
    if (this.configuration && this.configuration.reducers) {
      Object.keys(this.configuration.reducers).forEach(x => {
        appStore.injectReducer(x, this.configuration.reducers[x]);
      });
    }
  }

  /**
   * Gt all overrides
   */
  static getOverrides() {
    return this.configuration && this.configuration.overrides
      ? this.configuration.overrides
      : null;
  }

  /**
   * Get config
   */
  static getConfig(): AppConfig {
    return this.configuration && this.configuration.config
      ? this.configuration.config
      : {};
  }

  /**
   * Get styles
   */
  static getStyles() {
    return this.configuration && this.configuration.styles
      ? this.configuration.styles
      : null;
  }

  /**
   * Get routes
   */
  static getRoutes() {
    return _.get(this.configuration, 'routes', []);
  }

  /**
   * Get CustomNavigation
   */
  static getCustomNavigation() {
    return _.get(this.configuration, 'CustomNavigation', null);
  }

  /**
   * Override text
   *
   * @param text
   */
  static overrideText(text: string) {
    const textOverrides = _.get(this.configuration, 'overrides.text', {});
    return textOverrides[text] ? textOverrides[text] : text;
  }

  /**
   * Return original or overriden component
   *
   * @param OriginalComponent
   * @param overridePath
   */
  static thisOrOverridenComponent(OriginalComponent, overridePath) {
    const SelectedButton: React.FC<any> = props => {
      if (_.get(OverrideService.getOverrides(), overridePath)) {
        const CustomButton: any = _.get(
          OverrideService.getOverrides(),
          overridePath
        );
        return <CustomButton {...props} />;
      }
      return <OriginalComponent {...props} />;
    };
    return SelectedButton;
  }

  /**
   * Return original or overriden colors
   */
  static getThisOrOverridenColors() {
    if (OverrideService.getStyles() && OverrideService.getStyles().colors) {
      return {
        ...colors,
        ...OverrideService.getStyles().colors
      };
    }
    return {
      ...colors
    };
  }
}
