import React from 'react';

export default class KabnBottomWaveForMainPage extends React.Component<
  any,
  any
> {
  render() {
    return (
      <div className="svg-container">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="40%">
              <stop
                offset="0%"
                style={{ stopColor: '#3d578d', stopOpacity: 1 }}
              />
              <stop
                offset="30%"
                style={{ stopColor: '#3d578d', stopOpacity: 0.9 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: '#7a7afc', stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>

          <path
            fill="url(#gradient)"
            fillOpacity="1"
            d="M0,32L80,69.3C160,107,320,181,480,202.7C640,224,800,192,960,154.7C1120,117,1280,75,1360,53.3L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
    );
  }
}
