/* eslint-disable default-case */
import {
  MEMBER_AUTH_LOADED,
  MEMBER_STATUS_UPDATE_RECEIVED
} from '../auth/AuthActions'

export function paymentDevices(state = [] as any, action: { type: any; payload: { paymentDevices: any; }; }) {
  switch(action.type) {
    case MEMBER_AUTH_LOADED:
     return action.payload.paymentDevices || [];
    case MEMBER_STATUS_UPDATE_RECEIVED:
      let paymentDevices = state ? [...state] : [];
      let updatedBalances = action.payload.paymentDevices;
      updatedBalances.forEach(card => {
        paymentDevices.map(x => {
          if(x.id === card.id) {
            x.balance = card.balance;
            x.status = card.status;
            x.statusText = card.statusText;
          }
        })
      });

      return paymentDevices;
  }
  return [...state];
}


export default paymentDevices;
