import React from 'react';

export const ValidationErrors = (props: any) => {
  const messages: any[] = [];

  if (props.handler) {
    props.handler
      .getErrors()
      .filter((x: { field: any }) => x.field === props.name)
      .forEach((error: { message: any }) => messages.push(error.message));
  }
  if (messages.length === 0) {
    return null;
  }
  return (
    <div className="validation-message" key={`${props.name}-error`}>
      {messages.join('<br/>')}
    </div>
  );
};

export default ValidationErrors;
