import overrides from './overrides';
import styles from './styles';
// import routes from './routes';
import reducers from './reducers';
import config from './config/index';
import CustomNavigation from './navigation';

const configuration = {
  overrides,
  styles,
  // routes,
  config,
  reducers,
  CustomNavigation
};

export default configuration;
